import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { addPlayer } from '../../services/api';
import clubs from '../../data/clubs.json';
import teammates from '../../data/teammates.json';
import countries from '../../data/country.json';
import dts from '../../data/dts.json';
import positions from '../../data/positions.json';
import './AddPlayerForm.css';

const AddPlayerForm = () => {
    const [player, setPlayer] = useState({
        nombre: '',
        pais: '',
        posicion: '',
        fecha_nacimiento: '',
        clubes: [],
        teammates: [],
        img_pais: '',
        african_player: false,
        champions: false,
        balon_oro: false,
        europa_league: false,
        concacaf_player: false,
        campeon_mundo: false,
        eurocopa: false,
        copa_america: false,
        jjoo: false,
        maxTransferValue: 0,
        bota_oro: false,
        campeon_mundo_clubes: false,
        dirigidoPor: []
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlayer((prevPlayer) => ({
            ...prevPlayer,
            [name]: value
        }));

        if (name === 'pais') {
            setPlayer((prevPlayer) => ({
                ...prevPlayer,
                img_pais: getCountryFlag(value)
            }));
        }
    };

    const handleMultiSelectChange = (e, fieldName) => {
        const { value } = e.target;
        setPlayer((prevPlayer) => ({
            ...prevPlayer,
            [fieldName]: [...new Set([...prevPlayer[fieldName], value])]
        }));
    };

    const handleRemoveSelected = (item, fieldName) => {
        setPlayer((prevPlayer) => ({
            ...prevPlayer,
            [fieldName]: prevPlayer[fieldName].filter(i => i !== item)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Crear un nuevo objeto omitiendo los campos no seleccionados
        const filteredPlayer = Object.keys(player).reduce((acc, key) => {
            if (
                player[key] !== '' &&
                player[key] !== false &&
                !(Array.isArray(player[key]) && player[key].length === 0)
            ) {
                acc[key] = player[key];
            }
            return acc;
        }, {});

        // Convertir código de país a nombre completo
        if (filteredPlayer.pais) {
            filteredPlayer.pais = countries[filteredPlayer.pais];
        }

        // Formatear fecha de nacimiento a dd.mm.aaaa
        if (filteredPlayer.fecha_nacimiento) {
            const dateParts = filteredPlayer.fecha_nacimiento.split('-');
            filteredPlayer.fecha_nacimiento = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
        }

        try {
            await addPlayer(filteredPlayer);

            // Reset form
            setPlayer({
                nombre: '',
                pais: '',
                posicion: '',
                fecha_nacimiento: '',
                clubes: [],
                teammates: [],
                img_pais: '',
                african_player: false,
                champions: false,
                balon_oro: false,
                europa_league: false,
                concacaf_player: false,
                campeon_mundo: false,
                eurocopa: false,
                copa_america: false,
                jjoo: false,
                maxTransferValue: 0,
                bota_oro: false,
                campeon_mundo_clubes: false,
                dirigidoPor: []
            });

            toast.success('Jugador agregado con éxito');
        } catch (error) {
            console.error('Error adding player:', error);
            toast.error('Error al agregar jugador');
        }
    };

    const getCountryFlag = (countryCode) => {
        return `https://flagcdn.com/16x12/${countryCode}.png`;
    };

    return (
        <div className="form-container">
            <ToastContainer />
            <form onSubmit={handleSubmit} className="player-form">
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="nombre">Nombre</label>
                        <input
                            type="text"
                            name="nombre"
                            id="nombre"
                            placeholder="Nombre"
                            value={player.nombre}
                            onChange={handleChange}
                            required
                            className="form-input"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="pais">País</label>
                        <select
                            name="pais"
                            id="pais"
                            value={player.pais}
                            onChange={handleChange}
                            required
                            className="form-select"
                        >
                            <option value="">Selecciona un país</option>
                            {Object.entries(countries).map(([code, name]) => (
                                <option key={code} value={code}>{name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="posicion">Posición</label>
                        <select
                            name="posicion"
                            id="posicion"
                            value={player.posicion}
                            onChange={handleChange}
                            required
                            className="form-select"
                        >
                            <option value="">Selecciona una posición</option>
                            {Object.keys(positions).map(pos => (
                                <option key={pos} value={pos}>{pos}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>
                        <input
                            type="date"
                            name="fecha_nacimiento"
                            id="fecha_nacimiento"
                            value={player.fecha_nacimiento}
                            onChange={handleChange}
                            required
                            className="form-input"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="clubes">Clubes</label>
                        <select
                            name="clubes"
                            id="clubes"
                            onChange={(e) => handleMultiSelectChange(e, 'clubes')}
                            className="form-select"
                        >
                            <option value="">Selecciona clubes</option>
                            {Object.keys(clubs).map(club => (
                                <option key={club} value={club}>{club}</option>
                            ))}
                        </select>
                        <div className="selected-items">
                            {player.clubes.map((club, index) => (
                                <div key={index} className="selected-item">
                                    {club} <button type="button" onClick={() => handleRemoveSelected(club, 'clubes')}>x</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="teammates">Compañeros de Equipo</label>
                        <select
                            name="teammates"
                            id="teammates"
                            onChange={(e) => handleMultiSelectChange(e, 'teammates')}
                            className="form-select"
                        >
                            <option value="">Selecciona compañeros de equipo</option>
                            {Object.keys(teammates).map(teammate => (
                                <option key={teammate} value={teammate}>{teammate}</option>
                            ))}
                        </select>
                        <div className="selected-items">
                            {player.teammates.map((teammate, index) => (
                                <div key={index} className="selected-item">
                                    {teammate} <button type="button" onClick={() => handleRemoveSelected(teammate, 'teammates')}>x</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="dirigidoPor">Dirigido Por</label>
                        <select
                            name="dirigidoPor"
                            id="dirigidoPor"
                            onChange={(e) => handleMultiSelectChange(e, 'dirigidoPor')}
                            className="form-select"
                        >
                            <option value="">Selecciona entrenadores</option>
                            {Object.keys(dts).map(dt => (
                                <option key={dt} value={dt}>{dt}</option>
                            ))}
                        </select>
                        <div className="selected-items">
                            {player.dirigidoPor.map((dt, index) => (
                                <div key={index} className="selected-item">
                                    {dt} <button type="button" onClick={() => handleRemoveSelected(dt, 'dirigidoPor')}>x</button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="african_player">African Player</label>
                        <select
                            name="african_player"
                            id="african_player"
                            value={player.african_player}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="champions">Champions</label>
                        <select
                            name="champions"
                            id="champions"
                            value={player.champions}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="balon_oro">Balón de Oro</label>
                        <select
                            name="balon_oro"
                            id="balon_oro"
                            value={player.balon_oro}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="europa_league">Europa League</label>
                        <select
                            name="europa_league"
                            id="europa_league"
                            value={player.europa_league}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="concacaf_player">Concacaf Player</label>
                        <select
                            name="concacaf_player"
                            id="concacaf_player"
                            value={player.concacaf_player}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="campeon_mundo">Campeón del Mundo</label>
                        <select
                            name="campeon_mundo"
                            id="campeon_mundo"
                            value={player.campeon_mundo}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="eurocopa">Eurocopa</label>
                        <select
                            name="eurocopa"
                            id="eurocopa"
                            value={player.eurocopa}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="copa_america">Copa América</label>
                        <select
                            name="copa_america"
                            id="copa_america"
                            value={player.copa_america}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="jjoo">JJOO</label>
                        <select
                            name="jjoo"
                            id="jjoo"
                            value={player.jjoo}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="bota_oro">Bota de Oro</label>
                        <select
                            name="bota_oro"
                            id="bota_oro"
                            value={player.bota_oro}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="campeon_mundo_clubes">Mundial de Clubes</label>
                        <select
                            name="campeon_mundo_clubes"
                            id="campeon_mundo_clubes"
                            value={player.campeon_mundo_clubes}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Sí</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="maxTransferValue">Valor de Transferencia</label>
                        <input
                            type="number"
                            name="maxTransferValue"
                            id="maxTransferValue"
                            value={player.maxTransferValue}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                </div>
                <button type="submit" className="form-button">Agregar Jugador</button>
            </form>
        </div>
    );
};

export default AddPlayerForm;





