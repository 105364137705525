import clubs from '../data/clubsUru.json'
import countries from '../data/countriesUru.json'
import years from '../data/years.json'
/* import achievements from '../data/achievementsMex.json' */
import positions from '../data/positions.json'

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function isValidResult(result) {
    let highRatedClubsCount = 0;
    let countriesCount = 0;
    let yearsCount = 0;
    let positionsCount = 0;
    let campeonMundoIndex = -1;
    let BrasilIndex = -1;
    let ArgentinaIndex = -1;
    let ColombiaIndex = -1;
    let ParaguayIndex = -1;
    let laLuzIndex = -1;
    let cityTorqueIndex = -1;
    let uruguayMontevideoIndex = -1;
    let progresoIndex = -1;
    let cerritoIndex = -1;

    for (let i = 0; i < result.length; i++) {
        if (clubs[result[i]] >= 4) highRatedClubsCount++;
        if (countries.hasOwnProperty(result[i])) countriesCount++;
        if (years.hasOwnProperty(result[i])) yearsCount++;
        if (result[i] === "campeon_mundo") campeonMundoIndex = i;
        if (result[i] === "Brasil") BrasilIndex = i;
        if (result[i] === "Argentina") ArgentinaIndex = i;
        if (result[i] === "Colombia") ColombiaIndex = i;
        if (result[i] === "Paraguay") ParaguayIndex = i;
        if (result[i] === "la_luz") laLuzIndex = i;
        if (result[i] === "montevideo_city_torque") cityTorqueIndex = i;
        if (result[i] === "uruguay_montevideo") uruguayMontevideoIndex = i;
        if (result[i] === "progreso") progresoIndex = i;
        if (result[i] === "cerrito") cerritoIndex = i;
    }

    if (highRatedClubsCount < 3) return false;
    if (yearsCount > 1) return false;

    const notAllowedCountriesForCampeonMundo = ["Chile", "Uruguay", "Colombia", "Paraguay"];

    for (let i = 0; i < result.length; i++) {
        if (positions.hasOwnProperty(result[i])) positionsCount++;
    }

    if (positionsCount > 1) return false;

    if (campeonMundoIndex >= 0) {
        if (campeonMundoIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedCountriesForCampeonMundo.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedCountriesForCampeonMundo.includes(result[i])) return false;
            }
        }
    }

    if (countriesCount > 0) {
        let firstHalfCountryCount = 0;
        let secondHalfCountryCount = 0;
        for (let i = 0; i < 3; i++) {
            if (countries.hasOwnProperty(result[i])) firstHalfCountryCount++;
            if (result[i] === "african_player") firstHalfCountryCount++;
        }
        for (let i = 3; i < result.length; i++) {
            if (countries.hasOwnProperty(result[i])) secondHalfCountryCount++;
            if (result[i] === "african_player") secondHalfCountryCount++;
        }
        if (firstHalfCountryCount > 0 && secondHalfCountryCount > 0) return false;
    }

    if (BrasilIndex <3 && laLuzIndex >= 3) return false;
    if (laLuzIndex <3 && BrasilIndex >= 3) return false;
    if (BrasilIndex <3 && cityTorqueIndex >= 3) return false;
    if (cityTorqueIndex <3 && BrasilIndex >= 3) return false;
    if (BrasilIndex <3 && uruguayMontevideoIndex >= 3) return false;
    if (uruguayMontevideoIndex <3 && BrasilIndex >= 3) return false;
    if (BrasilIndex <3 && progresoIndex >= 3) return false;
    if (progresoIndex <3 && BrasilIndex >= 3) return false;
    if (ArgentinaIndex <3 && laLuzIndex >= 3) return false;
    if (laLuzIndex <3 && ArgentinaIndex >= 3) return false;
    if (ColombiaIndex <3 && progresoIndex >= 3) return false;
    if (progresoIndex <3 && ColombiaIndex >= 3) return false;
    if (ParaguayIndex <3 && cerritoIndex >= 3) return false;
    if (cerritoIndex <3 && ParaguayIndex >= 3) return false;
    if (ParaguayIndex <3 && uruguayMontevideoIndex >= 3) return false;
    if (uruguayMontevideoIndex <3 && ParaguayIndex >= 3) return false;
    if (ParaguayIndex <3 && cityTorqueIndex >= 3) return false;
    if (cityTorqueIndex <3 && ParaguayIndex >= 3) return false;
    if (ParaguayIndex <3 && progresoIndex >= 3) return false;
    if (progresoIndex <3 && ParaguayIndex >= 3) return false;
    
    return true;
}


function getRandomItemByCategory() {
    const clubItems = Object.keys(clubs);
    const countryItems = Object.keys(countries);
    const yearItems = Object.keys(years);
/*     const achievementItems = Object.keys(achievements); */
    const positionItems = Object.keys(positions);

    const clubWeight = 0.6;
    const countryWeight = 1.0;
    const yearWeight = 0.6;
/*     const achievementWeight = 1.0; */
    const positionWeight = 0.5;

    const totalWeight = clubItems.length * clubWeight +
        countryItems.length * countryWeight +
        yearItems.length * yearWeight +
  /*       achievementItems.length * achievementWeight + */
        positionItems.length * positionWeight;

    const randomValue = Math.random() * totalWeight;
    let accumulatedWeight = 0;

    for (let item of clubItems) {
        accumulatedWeight += clubWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of countryItems) {
        accumulatedWeight += countryWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of yearItems) {
        accumulatedWeight += yearWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

/*     for (let item of achievementItems) {
        accumulatedWeight += achievementWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    } */

    for (let item of positionItems) {
        accumulatedWeight += positionWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    throw new Error("No se pudo seleccionar un elemento aleatorio.");
}

export function generateUruArray() {
    let result;
    do {
        const selectedItems = new Set();

        while (selectedItems.size < 6) {
            const randomItem = getRandomItemByCategory();
            selectedItems.add(randomItem);
        }

        result = Array.from(selectedItems);
        shuffleArray(result);
    } while (!isValidResult(result));

    return result;
}