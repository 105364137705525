import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerUser, confirmUser, registerUserWithoutConfirmation } from '../../services/api';
import Navbar from '../../components/Navbar/Navbar';
import './Register.css';
import countriesJson from '../../data/countryEn.json';
import Loader from 'react-loader-spinner';

function Register() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [confirmationCode, setConfirmationCode] = useState('');
    const [isConfirming, setIsConfirming] = useState(false);
    const [loading, setLoading] = useState(false); // Estado de carga

    const isValidEmail = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    const isValidPassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        return hasUpperCase && hasNumber;
    };

    const isValidUsername = (username) => {
        const minLenght = 3;
        const maxLenght = 16;
        return username.length >= minLenght && username.length <= maxLenght;
    };

    const handleRegister = async () => {
        setError(null); // Limpia el estado de error
        setLoading(true); // Inicia el estado de carga
        try {
            if (!email || !password || !username || !country) {
                setError("Please fill in all fields.");
                setLoading(false);
                return;
            }

            if (!isValidUsername(username)) {
                setError("Username must be between 3 and 16 characters.");
                setLoading(false);
                return;
            }

            if (!isValidEmail(email)) {
                setError("Invalid email format.");
                setLoading(false);
                return;
            }

            if (!isValidPassword(password)) {
                setError("Password must contain at least one number and one uppercase letter.");
                setLoading(false);
                return;
            }

            const userData = {
                username,
                email,
                password,
                country
            };

            if (isConfirming) {
                await confirmUser({ confirmationCode });
                toast.success("Account successfully confirmed", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 9000
                });
                navigate('/login');
            } else {
                await registerUser(userData);
                setIsConfirming(true);
            }
        } catch (err) {
            const userData = {
                username,
                email,
                password,
                country
            };
            try {
                await registerUserWithoutConfirmation(userData);
                toast.info("Account successfully confirmed", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 9000
                });
                navigate('/login');
            } catch (innerError) {
                console.log(innerError);
                setError("An error occurred. Please try again later.");
            } 
        } finally {
            setLoading(false); // Finaliza el estado de carga
        }
    };

    return (
        <div className='container'>
            <Navbar />
            <div className="register-container">
                <div className="register-box">
                    <div className="register-header">
                        <a href="/" className="home-icon">🏠</a>
                        <h2>{isConfirming ? 'Confirm your account' : 'Register'}</h2>
                    </div>
                    <div className="register-form">
                        {loading ? (
                            <div className="loading-spinner">
                                <Loader type="TailSpin" color="#00BFFF" height={50} width={50} />
                            </div>
                        ) : !isConfirming ? (
                            <>
                                <input
                                    type="text"
                                    placeholder="Username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <select
                                    value={country}
                                    onChange={e => setCountry(e.target.value)}
                                    required
                                >
                                    <option value="">Select your country</option>
                                    {Object.entries(countriesJson).map(([code, name]) => (
                                        <option key={code} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        ) : (
                            <div>
                                <div className="confirmation-text">
                                    <p>We have sent a confirmation code to your email.</p>
                                    <p>Please check your spam folder</p>
                                </div>
                                <input
                                    type="text"
                                    className='confirmation-code'
                                    placeholder="Confirmation Code"
                                    value={confirmationCode}
                                    onChange={e => setConfirmationCode(e.target.value)}
                                />
                            </div>
                        )}
                        {!loading && (
                            <button onClick={handleRegister}>{isConfirming ? 'Confirm' : 'Register'}</button>
                        )}
                    </div>
                    {error && <p className="error-text">{error}</p>}
                    <div className="register-links">
                        <a href="/login">Already have an account? Log in</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;