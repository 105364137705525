import './NextButton.css';
import React from 'react';

const NextButton = ({
    onClick = () => {},          // Valor por defecto: función vacía
    changeTurn = () => {},       // Valor por defecto: función vacía
    turn = '',                   // Valor por defecto: string vacío
    socket = null,               // Valor por defecto: null
    player = null,               // Valor por defecto: null
    mode = '',                   // Valor por defecto: string vacío
    setStreak = () => {}         // Valor por defecto: función vacía
}) => {
    return (
        <div className="next-button-container">
            <button
                className="next-button"
                onClick={() => { onClick(socket); setStreak(0); }}
                disabled={turn !== player && player}
            >
                Change Teams ↻
            </button>
            <button
                className="skip-turn-button"
                onClick={() => changeTurn(socket)}
                disabled={(turn !== player && player) || (mode === 'bot' && turn === 'O')}
            >
                Skip Turn
            </button>

            <div className={`${turn === 'X' ? 'turn-indicator-red' : 'turn-indicator-blue'}`}>
                <p>Turn: {turn}</p>
            </div>
        </div>
    );
};

export default NextButton;