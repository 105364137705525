import Lottie from 'react-lottie';
import flameAnimation from '../../animations/flame.json';

export function FlameIcon({ streak }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: flameAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="streak-container" title="Current Streak">
            <div className="flame-wrapper">
                <Lottie
                    options={defaultOptions}
                    className="lottie-animation"
                />
                <span className="streak-number">{streak}</span>
            </div>
        </div>
    );
};
