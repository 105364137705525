import Swal from 'sweetalert2';

export function changeDifficulty(difficulty, setDifficulty, streak, setStreak) {
    if (streak > 1) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will lose your current streak if you change the difficulty!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change difficulty!'
        }).then((result) => {
            if (result.isConfirmed) {
                setDifficulty(difficulty);
                setStreak(0);
            }
        })
    } else {
        setDifficulty(difficulty);
    }
}

export function saveStreak(streak, setStreak, result) {
    if (result === 'X') {
        setStreak(streak + 1);
    } else if (result === 'O') {
        setStreak(0);
    } else {
        setStreak(streak);
    }
}