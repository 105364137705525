import React from "react";

const PoliticaPrivacidad = () => {

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 style={{color: 'white'}}>Política de privacidad</h1>
                    <p style={{color: 'white'}}>

                        1. INTRODUCCIÓN

                        Bienvenido a Footy TicTacToe. Esta política de privacidad describe cómo Footy TicTacToe recopila, usa y comparte información personal cuando utiliza nuestro Sitio.

                        Por favor lea la Política cuidadosamente antes de usar nuestro Sitio. Al acceder o utilizar el Sitio, usted acepta esta Política.

                        2. RECOPILACIÓN DE INFORMACIÓN

                        Al utilizar nuestro Sitio, podemos recopilar información personal sobre usted, como su nombre, dirección de correo electrónico, dirección IP y demás información no identificable. También recopilamos información sobre su interacción con nuestro Sitio, como los anuncios que ha visto o en los que ha hecho clic.

                        3. USO DE LA INFORMACIÓN

                        Utilizamos la información que recopilamos para:

                        Mostrar publicidad relevante a sus intereses.
                        Mejorar nuestro Sitio y nuestro juego.
                        Comprender cómo los usuarios interactúan con nuestro Sitio.
                        4. COMPARTIR DE LA INFORMACIÓN

                        Podemos compartir su información personal con terceros en los siguientes casos:

                        Para mostrarle publicidad, podríamos compartir su información con empresas de publicidad.
                        Si estamos obligados a hacerlo por ley, o en respuesta a un proceso legal.
                        Para proteger los derechos, la propiedad o la seguridad de nuestro Sitio, de nosotros o de otros.
                        5. SEGURIDAD

                        Tomamos medidas para proteger su información personal de la pérdida, el robo, el mal uso y el acceso no autorizado, la divulgación, la alteración y la destrucción.

                        6. CAMBIOS EN LA POLÍTICA

                        Podemos actualizar esta Política de Privacidad de vez en cuando. Si hacemos cambios materiales, le notificaremos antes de que los cambios entren en vigencia.

                        7. CONTACTO

                        Si tiene alguna pregunta sobre esta Política de Privacidad, por favor contáctenos en fty.tictactoe@gmail.com

                        Recuerda que este es un ejemplo general y puede no satisfacer todas las necesidades legales para tu sitio web específico. Te recomendamos que consultes con un asesor legal para asegurarte de que estás cumpliendo con todas las regulaciones pertinentes, especialmente si recoges o manejas información sensible. Además, puede que haya normativas específicas dependiendo del país en el que operes.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PoliticaPrivacidad;