import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { loginUser } from '../../services/api';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar/Navbar';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';

function Login() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleLogin = async () => {
        try {
            if (!username || !password) {
                setError("Please fill in all fields.");
                return;
            }

            const userData = await loginUser(username, password);

            toast.success("Welcome!", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 5000
            });
            navigate('/');
            login(userData);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className='container'>
            <Navbar />
            <div className="login-container">
                <div className="login-box">
                    <div className="login-header">
                        <a href="/" className="home-icon">🏠</a>
                        <h2>Welcome</h2>
                    </div>
                    <div className="login-form">
                        <input type="username" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
                        <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                        <button onClick={handleLogin}>Log In</button>
                    </div>
                    {error && <p className="error-text">{error}</p>}
                    <div className="login-links">
                        <a href="/register">Register</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
