import React from "react";
import { useState } from "react";
import AddPlayerForm from '../../components/AddPlayerForm/AddPlayerForm';
import AddPlayerFormArg from '../../components/AddPlayerForm/AddPlayerFormArg';
import EditPlayerForm from '../../components/EditPlayerForm/EditPlayerForm';
import EditPlayerFormArg from '../../components/EditPlayerForm/EditPlayerFormArg';
import './AdminPage.css'; // Importa el CSS aquí

const AdminPage = () => {
    const [isAdding, setIsAdding] = useState(true);
    const [isArgentina, setIsArgentina] = useState(false);

    const getTitle = () => {
        if (isAdding && !isArgentina) return "Agregar Jugador";
        if (!isAdding && !isArgentina) return "Modificar Jugador";
        if (isAdding && isArgentina) return "Agregar Jugador (Argentina)";
        if (!isAdding && isArgentina) return "Modificar Jugador (Argentina)";
        return "";
    };

    return (
        <div className="app-container">
            <div className="button-group">
                <button onClick={() => { setIsAdding(true); setIsArgentina(false); }} className={isAdding && !isArgentina ? 'active' : ''}>
                    Agregar Jugador
                </button>
                <button onClick={() => { setIsAdding(false); setIsArgentina(false); }} className={!isAdding && !isArgentina ? 'active' : ''}>
                    Modificar Jugador
                </button>
                <button onClick={() => { setIsAdding(true); setIsArgentina(true); }} className={isAdding && isArgentina ? 'active argentina' : 'argentina'}>
                    Agregar Jugador (Argentina)
                </button>
                <button onClick={() => { setIsAdding(false); setIsArgentina(true); }} className={!isAdding && isArgentina ? 'active argentina' : 'argentina'}>
                    Modificar Jugador (Argentina)
                </button>
            </div>
            <h1 className="page-title">{getTitle()}</h1>
            {!isArgentina ? (
                isAdding ? <AddPlayerForm /> : <EditPlayerForm />
            ) : (
                isAdding ? <AddPlayerFormArg /> : <EditPlayerFormArg />
            )}
        </div>
    );
};

export default AdminPage;
