import './TeamSquare.css'
import classNames from "classnames";

const TeamSquare = ({ team }) => {

    let teamClass = classNames({
        [`team--${team}`]: team !== null,
    });

    let imgClub;
    let context;

    try {
        context = require.context('../../img/teams', false, /\.jpg$/);
        imgClub = context(`./${team}.jpg`);
    } catch (err) {
        try {
            context = require.context('../../img/country', false, /\.jpg$/);
            imgClub = context(`./${team}.jpg`);
        } catch (err) {
            try {
                context = require.context('../../img/years', false, /\.jpg$/);
                imgClub = context(`./${team}.jpg`);
            } catch (err) {
                try {
                    context = require.context('../../img/achievements', false, /\.jpg$/);
                    imgClub = context(`./${team}.jpg`);
                } catch (err) {
                    try {
                        context = require.context('../../img/dt', false, /\.jpg$/);
                        imgClub = context(`./${team}.jpg`);
                    }
                    catch (err) {
                        try {
                            context = require.context('../../img/positions', false, /\.jpg$/);
                            imgClub = context(`./${team}.jpg`);
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                }
            }
        }
    }

    return (
        <div className={teamClass}>
            <img src={imgClub} alt={team} className="team" />
        </div>
    )
}

export default TeamSquare;