import React, { useState } from 'react';
import PlayerSearch from './PlayerSearchArg';
import { ToastContainer, toast } from 'react-toastify';
import { updatePlayerArg, deletePlayerArg } from '../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import clubs from '../../data/clubsArg.json';
import countries from '../../data/country.json';
import dts from '../../data/dtsArg.json';
import positions from '../../data/positions.json';
import '../AddPlayerForm/AddPlayerForm.css';

const EditPlayerFormArg = () => {
    const [player, setPlayer] = useState({
        id: '',
        nombre: '',
        pais: '',
        posicion: '',
        fecha_nacimiento: '',
        clubes: [],
        img_pais: '',
        copa_argentina: false,
        copa_america: false,
        campeon_mundo: false,
        dirigidoPor: []
    });
    const [originalPlayer, setOriginalPlayer] = useState(null);

    const handleSelect = (selectedPlayer) => {
        const formattedPlayer = {
            ...selectedPlayer,
            id: selectedPlayer._id,
            pais: Object.entries(countries).find(([code, name]) => name === selectedPlayer.pais)?.[0] || '',
            fecha_nacimiento: selectedPlayer.fecha_nacimiento.split('.').reverse().join('-'),
            copa_argentina: Boolean(selectedPlayer.copa_argentina),
            copa_america: Boolean(selectedPlayer.copa_america),
            campeon_mundo: Boolean(selectedPlayer.campeon_mundo),
            clubes: selectedPlayer.clubes || []
        };
        setPlayer(formattedPlayer);
        setOriginalPlayer(formattedPlayer);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setPlayer((prevPlayer) => ({
            ...prevPlayer,
            [name]: typeof prevPlayer[name] === 'boolean' ? value === 'true' : value
        }));
    
        if (name === 'pais') {
            setPlayer((prevPlayer) => ({
                ...prevPlayer,
                img_pais: getCountryFlag(value)
            }));
        }
    };

    const handleMultiSelectChange = (e, fieldName) => {
        const { value } = e.target;
        setPlayer((prevPlayer) => ({
            ...prevPlayer,
            [fieldName]: [...new Set([...prevPlayer[fieldName], value])]
        }));
    };

    const handleRemoveSelected = (item, fieldName) => {
        setPlayer((prevPlayer) => ({
            ...prevPlayer,
            [fieldName]: prevPlayer[fieldName].filter(i => i !== item)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const updatedPlayer = {
            ...player,
            pais: countries[player.pais],
            fecha_nacimiento: player.fecha_nacimiento.split('-').reverse().join('.'),
            copa_argentina: Boolean(player.copa_argentina),
            copa_america: Boolean(player.copa_america),
            campeon_mundo: Boolean(player.campeon_mundo),
            clubes: player.clubes
        };
    
        try {
            await updatePlayerArg(updatedPlayer); 
            toast.success('Jugador actualizado con éxito');
            setOriginalPlayer(null); 
            handleAfterSubmit();
        } catch (error) {
            console.error('Error updating player:', error);
            toast.error('Error al actualizar el jugador');
        }
    };

    const handleCancel = () => {
        if (originalPlayer) {
            setPlayer(originalPlayer);
        } else {
            setPlayer({
                id: '',
                nombre: '',
                pais: '',
                posicion: '',
                fecha_nacimiento: '',
                clubes: [],
                img_pais: '',
                copa_argentina: false,
                copa_america: false,
                campeon_mundo: false,
                dirigidoPor: []
            });
        }
    };

    const handleAfterSubmit = () => {
        setPlayer({
            id: '',
            nombre: '',
            pais: '',
            posicion: '',
            fecha_nacimiento: '',
            clubes: [],
            img_pais: '',
            campeon_mundo: false,
            copa_argentina: false,
            copa_america: false,
            dirigidoPor: []
        });
    };

    const handleDelete = async () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este jugador?')) {
            try {
                const response = await deletePlayerArg(player.id);
                if (!response.ok) {
                    throw new Error('Error al eliminar el jugador');
                }
                toast.success('Jugador eliminado con éxito');
                handleCancel();
            } catch (error) {
                console.error('Error deleting player:', error);
                toast.error('Error al eliminar el jugador');
            }
        }
    };

    const getCountryFlag = (countryCode) => {
        return `https://flagcdn.com/16x12/${countryCode}.png`;
    };

    return (
        <div className="form-container">
            <ToastContainer />
            <PlayerSearch onSelect={handleSelect} />
            {player.id && (
                <form onSubmit={handleSubmit} className="player-form">
                    <input type="hidden" name="id" value={player.id} />
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre</label>
                            <input
                                type="text"
                                name="nombre"
                                id="nombre"
                                placeholder="Nombre"
                                value={player.nombre}
                                onChange={handleChange}
                                required
                                className="form-input"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pais">País</label>
                            <select
                                name="pais"
                                id="pais"
                                value={player.pais}
                                onChange={handleChange}
                                required
                                className="form-select"
                            >
                                <option value="">Selecciona un país</option>
                                {Object.entries(countries).map(([code, name]) => (
                                    <option key={code} value={code}>{name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="posicion">Posición</label>
                            <select
                                name="posicion"
                                id="posicion"
                                value={player.posicion}
                                onChange={handleChange}
                                required
                                className="form-select"
                            >
                                <option value="">Selecciona una posición</option>
                                {Object.keys(positions).map((pos) => (
                                    <option key={pos} value={pos}>{pos}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>
                            <input
                                type="date"
                                name="fecha_nacimiento"
                                id="fecha_nacimiento"
                                value={player.fecha_nacimiento}
                                onChange={handleChange}
                                required
                                className="form-input"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="clubes">Clubes</label>
                            <select
                                name="clubes"
                                id="clubes"
                                onChange={(e) => handleMultiSelectChange(e, "clubes")}
                                className="form-select"
                            >
                                <option value="">Selecciona clubes</option>
                                {Object.keys(clubs).map((club) => (
                                    <option key={club} value={club}>{club}</option>
                                ))}
                            </select>
                            <div className="selected-items">
                                {player.clubes.map((club, index) => (
                                    <div key={index} className="selected-item">
                                        {club}{" "}
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveSelected(club, "clubes")}
                                        >
                                            x
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="dirigidoPor">Dirigido Por</label>
                            <select
                                name="dirigidoPor"
                                id="dirigidoPor"
                                onChange={(e) => handleMultiSelectChange(e, "dirigidoPor")}
                                className="form-select"
                            >
                                <option value="">Selecciona entrenadores</option>
                                {Object.keys(dts).map((dt) => (
                                    <option key={dt} value={dt}>
                                        {dt}
                                    </option>
                                ))}
                            </select>
                            <div className="selected-items">
                                {player.dirigidoPor.map((dt, index) => (
                                    <div key={index} className="selected-item">
                                        {dt}{" "}
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveSelected(dt, "dirigidoPor")}
                                        >
                                            x
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="copa_argentina">Copa Argentina</label>
                            <select
                                name="copa_argentina"
                                id="copa_argentina"
                                value={player.copa_argentina}
                                onChange={handleChange}
                                className="form-select"
                            >
                                <option value={false}>No</option>
                                <option value={true}>Sí</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="copa_america">Copa América</label>
                            <select
                                name="copa_america"
                                id="copa_america"
                                value={player.copa_america}
                                onChange={handleChange}
                                className="form-select"
                            >
                                <option value={false}>No</option>
                                <option value={true}>Sí</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="campeon_mundo">Campeón del Mundo</label>
                            <select
                                name="campeon_mundo"
                                id="campeon_mundo"
                                value={player.campeon_mundo}
                                onChange={handleChange}
                                className="form-select"
                            >
                                <option value={false}>No</option>
                                <option value={true}>Sí</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-buttons">
                        <button type="submit" className="form-button">
                            Actualizar Jugador
                        </button>
                        <button
                            type="button"
                            className="form-button cancel-button"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            className="form-button delete-button"
                            onClick={handleDelete}
                        >
                            Eliminar
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default EditPlayerFormArg;
