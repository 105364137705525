import React, { Component } from 'react';
import './HowToPlayModal.css';
import howtoplay from '../img/howtoplay/howtoplay.png';
import howtoplay2 from '../img/howtoplay/howtoplay2.png';
import howtoplay3 from '../img/howtoplay/howtoplay3.png';
import Xicon from '../img/x.png';

class HowToPlayModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImageIndex: 0,
        };
    }

    componentDidMount() {
        this.startCarousel();
    }

    componentWillUnmount() {
        this.stopCarousel();
    }

    startCarousel = () => {
        this.carouselInterval = setInterval(() => {
            const newIndex = (this.state.currentImageIndex + 1) % 3;
            this.setState({ currentImageIndex: newIndex });
        }, 3000);
    };

    stopCarousel = () => {
        clearInterval(this.carouselInterval);
    };

    renderImage = (index) => {
        const images = [howtoplay, howtoplay2, howtoplay3];
        const altTexts = [
            'Game instructions 1',
            'Game instructions 2',
            'Game instructions 3',
        ];

        return (
            <img
                key={index}
                src={images[index]}
                alt={altTexts[index]}
                className={`how-to-play-image${this.state.currentImageIndex === index ? ' active' : ''}`}
            />
        );
    };

    render() {
        const { onClose } = this.props;

        return (
            <div className="how-to-play-modal">
                <div className="how-to-play-content">
                    <div className="how-to-play-header">
                        <h3>How to Play</h3>
                        <button className="close-modal-button" onClick={onClose}>
                        <img className="x-icon" src={Xicon} alt="X" />
                        </button>
                    </div>
                    <div className="how-to-play-body">
                        <div className="how-to-play-carousel">
                            {Array.from({ length: 3 }, (_, index) => this.renderImage(index))}
                        </div>
                        <p className="how-to-play-text">
                            The objective of the game is to get three in a row, in any direction. To mark a square, you'll need to think of a player who meets the requirements of the pivot squares. Example: PSG and Ballon d'Or. A player who once played for PSG and won a Ballon d'Or.
                            <br /><br />
                            1. Type the name of a player in the search bar.
                            <br />
                            2. Select the player you want from the dropdown list.
                            <br />
                            3. Mark the square you want to mark with that player.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default HowToPlayModal;