import React, { useState, useContext, useEffect } from 'react';
import './RoomManagerPage.css';
import TimeLimitContext from '../../context/TimeLimitContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { createRoom, joinRoom } from '../../helpers/firebaseManagement';
import { generateCustomArray } from '../../helpers/generateCustomArray';
import { generateArgArray } from '../../helpers/generateArgArray';
import { generateMexArray } from '../../helpers/generateMexArray';
import { generateUruArray } from '../../helpers/generateUruArray';
import { generateEngArray } from '../../helpers/generateEngArray';
import GoBackButton from '../../img/goback.png';
import Xicon from '../../img/x.png';
import Navbar from '../../components/Navbar/Navbar';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loading';
import useFirebaseAuth from '../../hooks/useFirebaseAuth';

const RoomManagerPage = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const { timeLimit } = useContext(TimeLimitContext);
  const [roomCodeInput, setRoomCodeInput] = useState('');
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [user, setUser] = useState('null')
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState('normal');
  const location = useLocation();
  
  useFirebaseAuth(setLoading, setUser);

  useEffect(() => {
    if (location.state && location.state.mode) {
      if (location.state.mode === 'normal') {
        setMode('normal');
        setItems(generateCustomArray());
      } else if (location.state.mode === 'arg') {
        setMode('arg');
        setItems(generateArgArray());
      } else if (location.state.mode === 'mex') {
        setMode('mex');
        setItems(generateMexArray());
      } else if (location.state.mode === 'uru') {
        setMode('uru');
        setItems(generateUruArray());
      } else if (location.state.mode === 'eng') {
        setMode('eng');
        setItems(generateEngArray());
      } else {
        setMode('normal');
      }
    } 
  }, [location.state]);

  const handleCreateRoom = () => {
    if (user) {
      createRoom(user.uid, items, mode, timeLimit)
        .then((newRoomCode) => {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          navigate(`/room/${newRoomCode}`, { state: { direct: false } });
        });
    } else {
      console.error("You are not authenticated or you can't play against yourself");
    }
  };

  const handleJoinRoom = () => {
    if (user) {
      joinRoom(user.uid, roomCodeInput)
        .then((newRoomCode) => {
          navigate(`/room/${newRoomCode}`, { state: { direct: false } });
        });
    } else {
      console.error("Usuario no autenticado.");
    }
  };

  if (loading) {
    return <Loading />
  } else {
    return (
      <div className='container'>
        <Navbar />
        <button
        className="go-home-button"
        onClick={() => navigate('/')}
      >
        <img className="goHomeIcon" src={GoBackButton} alt="Back" />
      </button>
        <Title />
        <div className='room-actions'>

          <button className='create-room-button' onClick={handleCreateRoom} disabled={loading || !user}>Create Room</button>


          <div className='join-room'>
            <button className='join-room-button' onClick={() => setShowJoinModal(true)} disabled={loading || !user}>Join Room</button>
            {showJoinModal && (
              <div className='join-room-modal'>
                <div className='join-room-content'>
                  <div className='join-room-header'>
                    <h3>Enter the Room Code</h3>
                    <button className='close-modal-button' onClick={() => setShowJoinModal(false)}><img className="x-icon" src={Xicon} alt="X" /></button>
                  </div>
                  <div className='join-room-form'>
                    <input type='text' placeholder='Enter the Room Code' value={roomCodeInput} onChange={(e) => setRoomCodeInput(e.target.value)} />
                    <button className='join-room-submit-button' onClick={() => handleJoinRoom()}>Join Room</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div id="container-12af686062f23f6068597b46e4825913"></div>
      </div>
    );
  }
}

export default RoomManagerPage;