import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode'; // Importación correcta
import './AuthRoute.css';

function AdminRoute({ children }) {
    const { user, isUserLoading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isUserLoading) {
            let isAdmin = false;
            
            if (user && user.token) {
                try {
                    const decodedToken = jwtDecode(user.token); 
                    isAdmin = decodedToken.isAdmin;
                } catch (error) {
                    console.error("Error decoding token:", error);
                }
            }

            if (!isAdmin) {
                toast.error("Debes iniciar sesión como administrador para acceder a esta página.", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate("/");
            }
        }
    }, [user, navigate, isUserLoading]);

    if (isUserLoading) {
        return (
            <div className="authroute-container">
                <div className="authroute-content">
                    <div className="spinner"></div>
                    <p>Cargando...</p>
                </div>
            </div>
        );
    }

    return children;
}

export default AdminRoute;
