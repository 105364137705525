import React, { useEffect, useState, useRef } from 'react';
import './CountdownTimer.css';
import ballon from '../../img/ballon.png'; 

const CountdownTimer = ({ timeRemaining }) => {
  const [totalTime, setTotalTime] = useState(timeRemaining);
  const initialTime = useRef(timeRemaining); // Referencia para almacenar el valor inicial
  const percentage = (timeRemaining / totalTime) * 100;
  const timerClass =
    timeRemaining <= 5 ? 'danger' : timeRemaining <= 10 ? 'warning' : '';

  useEffect(() => {
    // Si timeRemaining vuelve a su valor máximo, reinicia totalTime
    if (timeRemaining === initialTime.current) {
      setTotalTime(timeRemaining);
    }
  }, [timeRemaining]);

  return (
    <div className={`countdown-timer ${timerClass}`}>
      <div className="field">
        <div className="track" style={{ width: `${percentage}%` }}></div> {/* Cambio aquí */}
        <div className="ball" style={{ left: `${percentage}%`, backgroundImage: `url(${ballon})` }}></div> {/* Cambio aquí */}
      </div>
      <div className="time-text">
        Time remaining: <span className="seconds">{timeRemaining} seconds</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
