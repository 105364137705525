import React from 'react';
import styled, { keyframes } from 'styled-components';
import ballon from '../../img/ballon.png';

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  
  50% {
    transform: translateY(-30px);
  }
`;

const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
`;

const colorChange = keyframes`
  0% { color: #0fffd5; }
  25% { color: #a2ff83; }
  50% { color: #a503c4; }
  75% { color: #ff3ebc; }
  100% { color: #ffb9cf; }
`;


const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

const Ball = styled.div`
  width: 50px;
  height: 50px;
  background: url(${ballon}) no-repeat center;
  background-size: cover;
  border-radius: 50%;
  animation: ${bounce} 1s infinite;
`;

const LoadingText = styled.p`
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  color: #555;
  animation: ${pulse} 1s infinite, ${colorChange} 5s infinite;
`;

const Loading = () => (
  <LoaderWrapper>
    <Ball />
    <LoadingText>Loading...</LoadingText>
  </LoaderWrapper>
);

export default Loading;

