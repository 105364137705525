import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify'

function NoAuthRoute({ children }) {
    let { user } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        if (user) {
            toast.info("Ya has iniciado sesión.", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            navigate("/");
        }
    }, [user, navigate]);

    return children;
}

export default NoAuthRoute;




