import React from 'react';
import Square from "../Square/Square";
import TeamSquare from "../TeamSquare/TeamSquare";
import SearchPlayers from "../SearchPlayers/SearchPlayers";
import logo from '../../img/logo.png';
import './Board.css';

const Board = ({ squares, onClick, turn, winningSquares, clubSquares, handlePlayerSelect, player, mode, league }) => {

    const createSquares = values => (
        values.map(value => {
            const square = squares[value];
            if (!square) {
                console.log(`No square found for value: ${value}`);
                return null; // or some other default value
            }
            return (
                <Square
                    winner={winningSquares.includes(value)}
                    turn={turn}
                    onClick={() => onClick(value)}
                    value={square.value} // fixed here
                    key={`square_${value}`}
                    playerName={square.playerName} // and here
                />
            )
        })
    );

    const createTeamSquares = values => (
        values.map(value => (
            <TeamSquare
                team={clubSquares[value]}
                key={`teamSquare_${value}`}
            />
        ))
    );


    return (
        <div className="board">
            <div className="row">
                <img className="logo" src={logo} alt="logo" />
                {createTeamSquares([0, 1, 2])}
            </div>
            <div className="row">
                {createTeamSquares([3])}
                {createSquares([0, 1, 2])}
            </div>
            <div className="row">
                {createTeamSquares([4])}
                {createSquares([3, 4, 5])}
            </div>
            <div className="row">
                {createTeamSquares([5])}
                {createSquares([6, 7, 8])}
            </div>
            <div>
                {mode === 'bot' && turn === 'O'
                    ? <div className="waiting-bot-turn">Waiting Bot Turn...</div>
                    : <SearchPlayers onSelect={handlePlayerSelect} turn={turn} player={player} league={league} />
                }
            </div>
        </div>
    );
}

export default Board;