import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import './OnlineGamePage.css'
import TimeLimitContext from '../../context/TimeLimitContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { app } from '../../firebase';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { joinRoom, updateItems, updateSelectedPlayer, updateGameStateAndPlayer, updateGameStateEnd, skipTurn, checkWinningSquares, resetFirebase, updateScore } from '../../helpers/firebaseManagement';
import { generateCustomArray } from '../../helpers/generateCustomArray';
import { generateArgArray } from '../../helpers/generateArgArray';
import { generateMexArray } from '../../helpers/generateMexArray';
import { generateUruArray } from '../../helpers/generateUruArray';
import { generateEngArray } from '../../helpers/generateEngArray';
import { winningPositions } from '../../helpers/winningPositions';
import { checkPlayer } from '../../helpers/checkPlayer';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar/Navbar';
import Title from '../../components/Title/Title';
import Board from '../../components/Board/Board';
import ScoreBoard from '../../components/ScoreBoard/ScoreBoard';
import NextButton from '../../components/NextButton/NextButton';
import PlayerSelected from '../../components/PlayerSelected/PlayerSelected';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import Loading from '../../components/Loading/Loading';
import useFirebaseAuth from '../../hooks/useFirebaseAuth';
import Swal from 'sweetalert2';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const OnlineGamePage = () => {
  const navigate = useNavigate();
  const [turn, setTurn] = useState('X');
  const [squares, setSquares] = useState(Array(9).fill({ value: '', playerName: '' }));
  const [winningSquares, setWinningSquares] = useState([]);
  const [score, setScore] = useState({
    X: 0,
    O: 0,
  });
  const [items, setItems] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { timeLimit, setTimeLimit } = useContext(TimeLimitContext);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [roomCode, setRoomCode] = useState(null);
  const [shapePlayer, setShapePlayer] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [mode, setMode] = useState('normal');
  const { urlRoomCode } = useParams();
  const database = useMemo(() => getDatabase(app), []);
  const [user, setUser] = useState('null')
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const itemMaping = {
    0: [items[0], items[3]],
    1: [items[1], items[3]],
    2: [items[2], items[3]],
    3: [items[0], items[4]],
    4: [items[1], items[4]],
    5: [items[2], items[4]],
    6: [items[0], items[5]],
    7: [items[1], items[5]],
    8: [items[2], items[5]],
  };

  useFirebaseAuth(setLoading, setUser);

  useEffect(() => {
    if (roomCode) {
      const db = getDatabase(app);
      const roomRef = ref(db, `rooms/${roomCode}`);
      const unsubscribe = onValue(roomRef, (snapshot) => {
        if (!snapshot.exists()) {
          Swal.fire({
            title: 'Error!',
            text: 'The room does not exist',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            navigate('/');
          }, 3000) // Espera 3 segundos antes de navegar
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [roomCode, setIsGameStarted, navigate]);


  useEffect(() => {
    if (user) {
      if (location.state && location.state.direct !== null) {
        setRoomCode(urlRoomCode);
      } else {
        joinRoom(user.uid, urlRoomCode)
          .then((roomCode) => {
            setRoomCode(roomCode);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [user, urlRoomCode, location.state]);

  useEffect(() => {
    if (mode === 'normal') {
      updateItems(roomCode, generateCustomArray());
    } else if (mode === 'arg') {
      updateItems(roomCode, generateArgArray());
    } else if (mode === 'mex') {
      updateItems(roomCode, generateMexArray());
    } else if (mode === 'uru') {
      updateItems(roomCode, generateUruArray());
    } else if (mode === 'eng') {
      updateItems(roomCode, generateEngArray());
    }
  }, [mode, roomCode]);

  useEffect(() => {
    if (user === null || roomCode === null) {
      return;
    }

    const roomRef = ref(database, `rooms/${roomCode}`);

    const valueChangeUnSub = onValue(roomRef, (snapshot) => {
      const roomData = snapshot.val();

      if (roomData === null) {
        new Promise(resolve => setTimeout(resolve, 2000)).then(() => navigate('/'));
        return;
      }

      if (roomData.hasOwnProperty("roomCode")) {
        setRoomCode(roomData.roomCode);
      }

      if (roomData.hasOwnProperty("mode")) {
        setMode(roomData.mode);
      }

      if (roomData.hasOwnProperty("items")) {
        setItems(roomData.items);
      }
      if (roomData.hasOwnProperty("turn")) {
        setTurn(roomData.turn);
      }
      if (roomData.hasOwnProperty("squares")) {
        // Reemplazar las cadenas vacías con valores null
        const nullSquares = roomData.squares.map(square => ({ value: square.value, playerName: square.playerName })
        );
        setSquares(nullSquares);
      }
      if (roomData.hasOwnProperty("winningSquares")) {
        setWinningSquares(roomData.winningSquares);
      }
      if (roomData.hasOwnProperty("score")) {
        setScore(roomData.score);
      }
      if (roomData.hasOwnProperty("isGameStarted")) {
        setIsGameStarted(roomData.isGameStarted);
      }
      if (roomData.hasOwnProperty("selectedPlayer")) {
        const nullSelectedPlayer = roomData.selectedPlayer === '' ? null : roomData.selectedPlayer;
        setSelectedPlayer(nullSelectedPlayer);
      }
      if (roomData.hasOwnProperty("timeLimit")) {
        setTimeLimit(roomData.timeLimit);
      }
      if (roomData.hasOwnProperty("player1") && roomData.hasOwnProperty("player2")) {
        if (user.uid === roomData.player1) {
          setShapePlayer('X');
        } else {
          setShapePlayer('O');
        }
      }
    });

    return () => {
      off(roomRef, "value", valueChangeUnSub);
    };
  }, [user, roomCode, database, navigate, setTimeLimit]);

  const handleSkipTurn = useCallback(() => {
    const newTurn = turn === 'X' ? 'O' : 'X';
    if (turn === shapePlayer) {
      setTurn(newTurn);
      skipTurn(roomCode, newTurn, timeLimit);
    }
  }, [roomCode, turn, shapePlayer, timeLimit]);


  useEffect(() => {
    if (turn === shapePlayer) {
      setTimeRemaining(timeLimit);
      const timer = setInterval(() => {
        setTimeRemaining((prevTimeRemaining) => {
          if (prevTimeRemaining === 0) {
            handleSkipTurn();
            setSelectedPlayer(null);
            return timeLimit;
          }
          return prevTimeRemaining - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLimit, turn, shapePlayer, handleSkipTurn]);

  const handleChangeItems = () => {
    if (mode === 'normal') {
      const newItems = generateCustomArray();
      if (turn === shapePlayer) {
        updateItems(roomCode, newItems);
      }
    } else if (mode === 'arg') {
      const newItems = generateArgArray();
      if (turn === shapePlayer) {
        updateItems(roomCode, newItems);
      }
    } else if (mode === 'mex') {
      const newItems = generateMexArray();
      if (turn === shapePlayer) {
        updateItems(roomCode, newItems);
      }
    } else if (mode === 'uru') {
      const newItems = generateUruArray();
      if (turn === shapePlayer) {
        updateItems(roomCode, newItems);
      }
    } else if (mode === 'eng') {
      const newItems = generateEngArray();
      if (turn === shapePlayer) {
        updateItems(roomCode, newItems);
      }
    }
  };

  const handlePlayerSelect = (player) => {
    if (turn === shapePlayer) {
      updateSelectedPlayer(roomCode, player);
    }
  }

  const handleClick = async (square) => {
    if (turn !== shapePlayer) {
      toast.error('It is not your turn', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    if (!selectedPlayer) {
      toast.error('Select a player first', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    const newTurn = turn === 'X' ? 'O' : 'X';
    const [item1, item2] = itemMaping[square];
    const playedInItem1 = checkPlayer(selectedPlayer, item1);
    const playedInItem2 = checkPlayer(selectedPlayer, item2);

    if (playedInItem1 && playedInItem2) {
      let newSquares = [...squares];
      newSquares.splice(square, 1, { value: turn, playerName: selectedPlayer.nombre })
      checkForWinner(newSquares);
      await updateGameStateEnd(roomCode, newSquares, '');
    } else {
      toast.warn('The player does not meet the requirements. 😔You lost the turn.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      await updateGameStateAndPlayer(roomCode, newTurn, squares, '');
    }
  };

  const checkForWinner = newSquares => {
    for (let i = 0; i < winningPositions.length; i++) {
      const [a, b, c] = winningPositions[i];
      if (newSquares[a] && newSquares[a].value !== '' && newSquares[a].value === newSquares[b].value && newSquares[a].value === newSquares[c].value) {
        endGame(newSquares[a].value, winningPositions[i])
        return
      }
    }

    if (!newSquares.some(square => square.value === '')) {
      //empate
      endGame(null, Array.from(Array(10).keys()));
      return
    }
    skipTurn(roomCode, turn === 'X' ? 'O' : 'X', timeLimit);
  };

  const endGame = (result, winningPositions) => {
    setTurn(null);
    if (result !== null) {
      setScore({
        ...score,
        [result]: score[result] + 1,
      });
    }
    setWinningSquares(winningPositions);
    checkWinningSquares(roomCode, winningPositions);
    if (result && score[result] !== undefined) {
      const scoreFirebase = {
        ...score,
        [result]: score[result] + 1,
      };
      updateScore(roomCode, scoreFirebase);
    }
    setTimeout(reset, 3000);
  };

  const reset = () => {
    const newSquares = Array(9).fill({ value: '', playerName: '' })
    const newTurn = turn === 'X' ? 'O' : 'X';
    handleChangeItems();
    resetFirebase(roomCode, newTurn, newSquares);
  };

  if (loading) {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Loading />;
      </>
    )
  } else {

    return (
      <div className="container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {!isGameStarted ? (
          <div className='wait'>
            <div className='wait-message'>
              <div className='spinner'>
                <div className='spinner-dot'></div>
                <div className='spinner-dot'></div>
                <div className='spinner-dot'></div>
              </div>
              Waiting for another player to join...
            </div>
            <div className='room-code'>
              Room Code: <span className='code'>{roomCode}</span>
            </div>
            <div className="share-container">
              <CopyToClipboard text={window.location.href}
                onCopy={() => setIsCopied(true)}>
                <button className="share-button">Share Link</button>
              </CopyToClipboard>
              {isCopied ? <span className="copied-message">Link Copied!</span> : null}
            </div>

          </div>

        ) : (
          <>
            <Navbar />
            <Title />
            <div className="player-info">
              <p className="player-label">Player:</p>
              <p className={`player-shape ${shapePlayer === 'X' ? 'red' : 'blue'}`}>{shapePlayer}</p>
              {turn === shapePlayer ? (
                <p className="turn-message">It's your turn!</p>
              ) : (
                <p className="turn-message waiting-message">Waiting ...</p>
              )}
            </div>
            <ScoreBoard scoreO={score.O} scoreX={score.X} />
            {selectedPlayer && <PlayerSelected player={selectedPlayer} />}
            <NextButton onClick={reset} changeTurn={handleSkipTurn} turn={turn} socket={user.uid} player={shapePlayer} />
            {timeRemaining !== null && turn === shapePlayer && <CountdownTimer timeRemaining={timeRemaining} />}
            <Board winningSquares={winningSquares} turn={turn} squares={squares} onClick={handleClick} clubSquares={items} handlePlayerSelect={handlePlayerSelect} player={shapePlayer} league={mode} />
          </>
        )}
      </div>
    );
  }
}

export default OnlineGamePage;
