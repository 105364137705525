// firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCRBKfa1p9JrsA0eeK0i3YlJJmXt5vVTCE",
    authDomain: "footy-tictactoe-7d6b8.firebaseapp.com",
    databaseURL: "https://footy-tictactoe-7d6b8-default-rtdb.firebaseio.com",
    projectId: "footy-tictactoe-7d6b8",
    storageBucket: "footy-tictactoe-7d6b8.appspot.com",
    messagingSenderId: "7534333035",
    appId: "1:7534333035:web:abcdbe2789c815ada2b52e",
    measurementId: "G-CRYEVM7Q9G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Export the Firebase app instance and analytics instance
export { app, analytics };

