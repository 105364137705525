import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser, getAllUsers } from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import Navbar from '../../components/Navbar/Navbar';
import './RankingPage.css';

const RankingPage = () => {
    const [users, setUsers] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'totalPoints', direction: 'descending' });
    const [mode, setMode] = useState('Normal');
    const { user } = useAuth();
    const [userData, setUserData] = useState({});

    const getSortKey = (baseKey) => {
        return mode === 'Argentina' ? `${baseKey}Arg` : baseKey;
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getAllUsers();
                if (response && Array.isArray(response.users)) {
                    setUsers(response.users);
                    // Ordenar por defecto por totalPoints al cargar
                    const sortedUsers = [...response.users].sort((a, b) => b.totalPoints - a.totalPoints);
                    setUsers(sortedUsers);
                } else {
                    toast.error("Data format is incorrect");
                }
            } catch (error) {
                toast.error("Failed to fetch users");
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        if (user) {
            const fetchUserData = async () => {
                try {
                    const response = await getUser();
                    if (response) {
                        setUserData(response);
                    } else {
                        toast.error("Data format is incorrect");
                    }
                } catch (error) {
                    toast.error("Failed to fetch user data");
                }
            };
            fetchUserData();
        }
    }, [user]);

    const sortTable = (baseKey) => {
        const key = getSortKey(baseKey);
        const direction = sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        setSortConfig({ key, direction });
        const sortedUsers = [...users].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });
        setUsers(sortedUsers);
    };

    return (
        <div className="container">
            <Navbar />
            <h1 className="page-title">
                <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>Ranking</a>
            </h1>
            <div className="mode-selector">
                <label htmlFor="mode">Stats from:</label>
                <select
                    id="mode"
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                >
                    <option value="Normal">Normal</option>
                    <option value="Argentina">Argentina</option>
                </select>
            </div>
            {userData.user && (
                <div className="table-wrapper">
                    <table className="ranking-table">
                        <thead>
                            <tr>
                                <th className="fixed-width" rowSpan="2">Username</th>
                                <th className="fixed-width" rowSpan="2">Country</th>
                                <th className="fixed-width" rowSpan="2" onClick={() => sortTable('totalPoints')}>Total Points</th>
                                <th colSpan="3">Best Streak</th>
                                <th colSpan="3">Wins</th>
                                <th colSpan="3">Losses</th>
                            </tr>
                            <tr>
                                <th onClick={() => sortTable('bestStreakHard')}>Hard</th>
                                <th onClick={() => sortTable('bestStreakMedium')}>Medium</th>
                                <th onClick={() => sortTable('bestStreakEasy')}>Easy</th>
                                <th onClick={() => sortTable('winsHard')}>Hard</th>
                                <th onClick={() => sortTable('winsMedium')}>Medium</th>
                                <th onClick={() => sortTable('winsEasy')}>Easy</th>
                                <th onClick={() => sortTable('lossesHard')}>Hard</th>
                                <th onClick={() => sortTable('lossesMedium')}>Medium</th>
                                <th onClick={() => sortTable('lossesEasy')}>Easy</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key="logged-in-user">
                                <td>{userData.user.username}</td>
                                <td>{userData.user.country}</td>
                                <td>{userData.user.totalPoints}</td>
                                <td>{mode === 'Argentina' ? userData.user.bestStreakHardArg : userData.user.bestStreakHard}</td>
                                <td>{mode === 'Argentina' ? userData.user.bestStreakMediumArg : userData.user.bestStreakMedium}</td>
                                <td>{mode === 'Argentina' ? userData.user.bestStreakEasyArg : userData.user.bestStreakEasy}</td>
                                <td>{mode === 'Argentina' ? userData.user.winsHardArg : userData.user.winsHard}</td>
                                <td>{mode === 'Argentina' ? userData.user.winsMediumArg : userData.user.winsMedium}</td>
                                <td>{mode === 'Argentina' ? userData.user.winsEasyArg : userData.user.winsEasy}</td>
                                <td>{mode === 'Argentina' ? userData.user.lossesHardArg : userData.user.lossesHard}</td>
                                <td>{mode === 'Argentina' ? userData.user.lossesMediumArg : userData.user.lossesMedium}</td>
                                <td>{mode === 'Argentina' ? userData.user.lossesEasyArg : userData.user.lossesEasy}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            <div className="table-wrapper">
                <table className="ranking-table">
                    <thead>
                        <tr>
                            <th className="fixed-width" rowSpan="2">Rank</th>
                            <th className="fixed-width" rowSpan="2">Username</th>
                            <th className="fixed-width" rowSpan="2">Country</th>
                            <th className="fixed-width" rowSpan="2" onClick={() => sortTable('totalPoints')}>Total Points</th>
                            <th colSpan="3">Best Streak</th>
                            <th colSpan="3">Wins</th>
                            <th colSpan="3">Losses</th>
                        </tr>
                        <tr>
                            <th onClick={() => sortTable('bestStreakHard')}>Hard</th>
                            <th onClick={() => sortTable('bestStreakMedium')}>Medium</th>
                            <th onClick={() => sortTable('bestStreakEasy')}>Easy</th>
                            <th onClick={() => sortTable('winsHard')}>Hard</th>
                            <th onClick={() => sortTable('winsMedium')}>Medium</th>
                            <th onClick={() => sortTable('winsEasy')}>Easy</th>
                            <th onClick={() => sortTable('lossesHard')}>Hard</th>
                            <th onClick={() => sortTable('lossesMedium')}>Medium</th>
                            <th onClick={() => sortTable('lossesEasy')}>Easy</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => {
                            let rankClass = '';
                            if (index === 0) rankClass = 'gold';
                            if (index === 1) rankClass = 'silver';
                            if (index === 2) rankClass = 'bronze';

                            return (
                                <tr key={user.id || index} className={rankClass}>
                                    <td>{index + 1}</td>
                                    <td className='username'>{user.username}</td>
                                    <td>{user.country}</td>
                                    <td className='total-points'>{user.totalPoints}</td>
                                    <td>{mode === 'Argentina' ? user.bestStreakHardArg : user.bestStreakHard}</td>
                                    <td>{mode === 'Argentina' ? user.bestStreakMediumArg : user.bestStreakMedium}</td>
                                    <td>{mode === 'Argentina' ? user.bestStreakEasyArg : user.bestStreakEasy}</td>
                                    <td>{mode === 'Argentina' ? user.winsHardArg : user.winsHard}</td>
                                    <td>{mode === 'Argentina' ? user.winsMediumArg : user.winsMedium}</td>
                                    <td>{mode === 'Argentina' ? user.winsEasyArg : user.winsEasy}</td>
                                    <td>{mode === 'Argentina' ? user.lossesHardArg : user.lossesHard}</td>
                                    <td>{mode === 'Argentina' ? user.lossesMediumArg : user.lossesMedium}</td>
                                    <td>{mode === 'Argentina' ? user.lossesEasyArg : user.lossesEasy}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default RankingPage;
