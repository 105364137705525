import React from "react";
import "./Title.css";

const Title = ({ onClick }) => {   // Aceptar props

    return (
        <div className="game-header">
            <h1 className="game-title" onClick={onClick}>Footy TicTacToe</h1>
        </div>
    );
};

export default Title;
