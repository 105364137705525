import React, { useEffect, useState } from 'react';
import { FaTwitter, FaTiktok, FaInstagram, FaUser, FaSignOutAlt, FaSignInAlt, FaUserPlus, FaGamepad } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getUser } from '../../services/api';
import './Navbar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (user) {
            getUser()
                .then(data => 
                    setUserData(data)
                )
                .catch(error => console.error('Error fetching user data:', error));
        }
    }, [user]);

    const handleLoginClick = () => navigate('/login');
    const handleRegisterClick = () => navigate('/register');
    const handleLogoutClick = () => {
        logout();
        navigate('/logout');
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <ul className="navbar-menu">
                    <li>
                        <a href="https://twitter.com/footytictactoe" target="_blank" rel="noopener noreferrer">
                            <FaTwitter />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tiktok.com/@footytictactoe" target="_blank" rel="noopener noreferrer">
                            <FaTiktok />
                        </a>
                    </li>
                    {user ? (
                        <li>
                            <p><FaUser /> {userData?.user?.username || 'user'}</p>
                        </li>
                    ) : (
                        <li>
                            <button onClick={handleLoginClick}><FaSignInAlt /> Login</button>
                        </li>
                    )}
                    {user ? (
                        <>
                            <button onClick={handleLogoutClick} className='logout-button'><FaSignOutAlt /></button>
                        </>
                    ) : (
                        <>
                            <button onClick={handleRegisterClick}><FaUserPlus /> Register</button>
                        </>
                    )}
                    <li>
                        <a href="https://www.instagram.com/footytictactoe/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.utdle.com" className="game-icon" target="_blank" rel="noopener noreferrer">
                            <FaGamepad />
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;