import './SearchPlayers.css'
import React, { useState, useEffect } from 'react';
import latinize from 'latinize';
import Select from 'react-select';
import debounce from 'lodash.debounce';

const backendURL = 'https://footy-294475855174.us-central1.run.app';

const SearchPlayers = ({ onSelect, turn, player, league }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const debouncedFetchPlayers = debounce(async (value) => {
      try {
        const normalizedValue = latinize(value);
        const url = league === 'arg'
          ? `${backendURL}/players/arg/search?search=${normalizedValue}`
          : `${backendURL}/players/search?search=${normalizedValue}`;

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const results = await response.json();
        setSearchResults(results);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    }, 500);

    if (searchValue.length >= 3) {
      debouncedFetchPlayers(searchValue);
    } else {
      setSearchResults([]);
    }

    return () => {
      debouncedFetchPlayers.cancel();
    };
  }, [searchValue, league]);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(latinize(value));
  };

  const handlePlayerSelect = (selectedOption) => {
    onSelect(selectedOption.value);
    setSearchValue('');
    setSearchResults([]);
  };

  const formatPlayerOptions = (players) => {
    return players.map((player) => {
      const yearOfBirth = player.fecha_nacimiento.split('.').pop();
      return {
        value: player,
        label: (
          <div className="player-option">
            <img src={player.img_pais} alt={player.img_pais} width="20" height="15" />
            <span>{` ${player.nombre} - ${player.posicion} - (${yearOfBirth})`}</span>
          </div>
        ),
      };
    });
  };

  return (
    <div className="search-players-container">
      <div className="search-input-container">
        <input
          type="text"
          placeholder="Search Player"
          value={searchValue}
          onChange={handleSearchChange}
          className="search-input"
          disabled={turn !== player}
        />
      </div>
      {searchValue.length >= 3 && searchResults.length > 0 &&
        <div className="search-select-container">
          <Select
            value={searchResults.length > 0 ? { value: searchResults[0], label: searchResults[0].nombre } : null}
            options={formatPlayerOptions(searchResults)}
            onChange={handlePlayerSelect}
            menuIsOpen
            isSearchable={false}
            classNamePrefix="react-select"
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          />
        </div>
      }
    </div>
  );
};

export default SearchPlayers;