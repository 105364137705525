import clubs from '../data/clubsEng.json'
import countries from '../data/countriesEng.json'
import years from '../data/years.json'
import positions from '../data/positions.json'

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function isValidResult(result) {
    let highRatedClubsCount = 0;
    let countriesCount = 0;
    let yearsCount = 0;
    let positionsCount = 0;
    let campeonMundoIndex = -1;
    let EspañaIndex = -1;
    let SueciaIndex = -1;
    let ItaliaIndex = -1;
    let AlemaniaIndex = -1;
    let PortugalIndex = -1;
    let BelgicaIndex = -1;
    let BrasilIndex = -1;
    let ArgentinaIndex = -1;
    let NoruegaIndex = -1;
    let burnleyIndex = -1;
    let sheffieldUnitedIndex = -1;
    let cardiffCityIndex = -1;
    let lutonTownIndex = -1;
    let brentfordIndex = -1;
    let sweanseaCityIndex = -1;
    let bourneMouthIndex = -1;
    let watfordIndex = -1;
    let brightonIndex = -1;
    let stokeCityIndex = -1;
    let hullCityIndex = -1;
    let leicesterCityIndex = -1;

    for (let i = 0; i < result.length; i++) {
        if (clubs[result[i]] >= 4) highRatedClubsCount++;
        if (countries.hasOwnProperty(result[i])) countriesCount++;
        if (years.hasOwnProperty(result[i])) yearsCount++;
        if (result[i] === "campeon_mundo") campeonMundoIndex = i;
        if (result[i] === "Argentina") ArgentinaIndex = i;
        if (result[i] === "Suecia") SueciaIndex = i;
        if (result[i] === "España") EspañaIndex = i;
        if (result[i] === "Italia") ItaliaIndex = i;
        if (result[i] === "Alemania") AlemaniaIndex = i;
        if (result[i] === "Portugal") PortugalIndex = i;
        if (result[i] === "Belgica") BelgicaIndex = i;
        if (result[i] === "Brasil") BrasilIndex = i;
        if (result[i] === "Noruega") NoruegaIndex = i;
        if (result[i] === "burnley") burnleyIndex = i;
        if (result[i] === "sheffield-united") sheffieldUnitedIndex = i;
        if (result[i] === "cardiff-city") cardiffCityIndex = i;
        if (result[i] === "luton-town") lutonTownIndex = i;
        if (result[i] === "brentford-fc") brentfordIndex = i;
        if (result[i] === "sweansea-city") sweanseaCityIndex = i;
        if (result[i] === "bournemouth") bourneMouthIndex = i;
        if (result[i] === "watford-fc") watfordIndex = i;
        if (result[i] === "brighton_and_hove_albion") brightonIndex = i;
        if (result[i] === "stoke-city") stokeCityIndex = i;
        if (result[i] === "hull-city") hullCityIndex = i;
        if (result[i] === "leicester") leicesterCityIndex = i;
    }

    if (highRatedClubsCount < 3) return false;
    if (yearsCount > 1) return false;

    const notAllowedCountriesForCampeonMundo = ["Chile", "Uruguay", "Colombia", "Paraguay"];

    for (let i = 0; i < result.length; i++) {
        if (positions.hasOwnProperty(result[i])) positionsCount++;
    }

    if (positionsCount > 1) return false;

    if (campeonMundoIndex >= 0) {
        if (campeonMundoIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedCountriesForCampeonMundo.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedCountriesForCampeonMundo.includes(result[i])) return false;
            }
        }
    }

    if (countriesCount > 0) {
        let firstHalfCountryCount = 0;
        let secondHalfCountryCount = 0;
        for (let i = 0; i < 3; i++) {
            if (countries.hasOwnProperty(result[i])) firstHalfCountryCount++;
            if (result[i] === "african_player") firstHalfCountryCount++;
        }
        for (let i = 3; i < result.length; i++) {
            if (countries.hasOwnProperty(result[i])) secondHalfCountryCount++;
            if (result[i] === "african_player") secondHalfCountryCount++;
        }
        if (firstHalfCountryCount > 0 && secondHalfCountryCount > 0) return false;
    }

    if (EspañaIndex <3 && burnleyIndex >= 3) return false;
    if (burnleyIndex <3 && EspañaIndex >= 3) return false;
    if (EspañaIndex <3 && sheffieldUnitedIndex >= 3) return false;
    if (sheffieldUnitedIndex <3 && EspañaIndex >= 3) return false;
    if (PortugalIndex <3 && cardiffCityIndex >= 3) return false;
    if (cardiffCityIndex <3 && PortugalIndex >= 3) return false;
    if (PortugalIndex <3 && lutonTownIndex >= 3) return false;
    if (lutonTownIndex <3 && PortugalIndex >= 3) return false;
    if (PortugalIndex <3 && burnleyIndex >= 3) return false;
    if (burnleyIndex <3 && PortugalIndex >= 3) return false;
    if (BrasilIndex <3 && brentfordIndex >= 3) return false;
    if (brentfordIndex <3 && BrasilIndex >= 3) return false;
    if (BrasilIndex <3 && sweanseaCityIndex >= 3) return false;
    if (sweanseaCityIndex <3 && BrasilIndex >= 3) return false;
    if (BrasilIndex <3 && lutonTownIndex >= 3) return false;
    if (lutonTownIndex <3 && BrasilIndex >= 3) return false;
    if (AlemaniaIndex <3 && bourneMouthIndex >= 3) return false;
    if (bourneMouthIndex <3 && AlemaniaIndex >= 3) return false;
    if (AlemaniaIndex <3 && lutonTownIndex >= 3) return false;
    if (lutonTownIndex <3 && AlemaniaIndex >= 3) return false;
    if (AlemaniaIndex <3 && watfordIndex >= 3) return false;
    if (watfordIndex <3 && AlemaniaIndex >= 3) return false;
    if (ItaliaIndex <3 && brightonIndex >= 3) return false;
    if (brightonIndex <3 && ItaliaIndex >= 3) return false;
    if (ItaliaIndex <3 && stokeCityIndex >= 3) return false;
    if (stokeCityIndex <3 && ItaliaIndex >= 3) return false;
    if (ItaliaIndex <3 && lutonTownIndex >= 3) return false;
    if (lutonTownIndex <3 && ItaliaIndex >= 3) return false;
    if (ArgentinaIndex <3 && hullCityIndex >= 3) return false;
    if (hullCityIndex <3 && ArgentinaIndex >= 3) return false;
    if (ArgentinaIndex <3 && burnleyIndex >= 3) return false;
    if (burnleyIndex <3 && ArgentinaIndex >= 3) return false;
    if (ArgentinaIndex <3 && hullCityIndex >= 3) return false;
    if (hullCityIndex <3 && ArgentinaIndex >= 3) return false;
    if (ArgentinaIndex <3 && lutonTownIndex >= 3) return false;
    if (lutonTownIndex <3 && ArgentinaIndex >= 3) return false;
    if (ArgentinaIndex <3 && stokeCityIndex >= 3) return false;
    if (stokeCityIndex <3 && ArgentinaIndex >= 3) return false;
    if (SueciaIndex <3 && bourneMouthIndex >= 3) return false;
    if (bourneMouthIndex <3 && SueciaIndex >= 3) return false;
    if (SueciaIndex <3 && cardiffCityIndex >= 3) return false;
    if (cardiffCityIndex <3 && SueciaIndex >= 3) return false;
    if (BelgicaIndex <3 && bourneMouthIndex >= 3) return false;
    if (bourneMouthIndex <3 && BelgicaIndex >= 3) return false;
    if (BelgicaIndex <3 && hullCityIndex >= 3) return false;
    if (hullCityIndex <3 && BelgicaIndex >= 3) return false;
    if (NoruegaIndex <3 && leicesterCityIndex >= 3) return false;
    if (leicesterCityIndex <3 && NoruegaIndex >= 3) return false;
    if (NoruegaIndex <3 && sweanseaCityIndex >= 3) return false;
    if (sweanseaCityIndex <3 && NoruegaIndex >= 3) return false;

    return true;
}


function getRandomItemByCategory() {
    const clubItems = Object.keys(clubs);
    const countryItems = Object.keys(countries);
    const yearItems = Object.keys(years);
/*     const achievementItems = Object.keys(achievements); */
    const positionItems = Object.keys(positions);

    const clubWeight = 0.6;
    const countryWeight = 1.0;
    const yearWeight = 0.6;
/*     const achievementWeight = 1.0; */
    const positionWeight = 0.5;

    const totalWeight = clubItems.length * clubWeight +
        countryItems.length * countryWeight +
        yearItems.length * yearWeight +
  /*       achievementItems.length * achievementWeight + */
        positionItems.length * positionWeight;

    const randomValue = Math.random() * totalWeight;
    let accumulatedWeight = 0;

    for (let item of clubItems) {
        accumulatedWeight += clubWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of countryItems) {
        accumulatedWeight += countryWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of yearItems) {
        accumulatedWeight += yearWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

/*     for (let item of achievementItems) {
        accumulatedWeight += achievementWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    } */

    for (let item of positionItems) {
        accumulatedWeight += positionWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    throw new Error("No se pudo seleccionar un elemento aleatorio.");
}

export function generateEngArray() {
    let result;
    do {
        const selectedItems = new Set();

        while (selectedItems.size < 6) {
            const randomItem = getRandomItemByCategory();
            selectedItems.add(randomItem);
        }

        result = Array.from(selectedItems);
        shuffleArray(result);
    } while (!isValidResult(result));

    return result;
}