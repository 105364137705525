// AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isUserLoading, setIsUserLoading] = useState(true); 

    useEffect(() => {
        const userData = localStorage.getItem('authUserData');
        if (userData) {
            setUser(JSON.parse(userData));
        }
        setIsUserLoading(false);
    }, []);

    const login = (userData) => {
        setUser(userData);
        localStorage.setItem('authUserData', JSON.stringify({ token: userData.token }));
        localStorage.setItem('refreshToken', userData.refreshToken); 
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('authUserData');
        localStorage.removeItem('refreshToken');
    };

    const contextValue = {
        user,
        login,
        logout,
        isUserLoading
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}