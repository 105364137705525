import React, { useState, useEffect } from "react";
import './PlayerFinderPage.css'
import Navbar from '../../components/Navbar/Navbar';
import Title from "../../components/Title/Title";
import GoBackButton from '../../img/goback.png';
import { useNavigate } from 'react-router-dom';
import latinize from 'latinize';

const PlayerFinderPageArg = () => {
    const clubMappings = {
        "Aldosivi": "aldosivi",
        "All Boys": "all-boys",
        "Argentinos Juniors": "argentinos-juniors",
        "Arsenal de Sarandí": "arsenal-de-sarandi",
        "Atlético Rafaela": "atletico-rafaela",
        "Atlético Tucumán": "atletico-tucuman",
        "Banfield": "banfield",
        "Barracas Central": "barracas-central",
        "Belgrano de Córdoba": "belgrano-de-cordoba",
        "Boca Juniors": "boca-juniors",
        "Central Córdoba SdE": "central-cordoba-sde",
        "Chacarita Juniors": "chacarita-juniors_2",
        "Colón de Santa Fe": "colon-de-santa-fe",
        "Defensa y Justicia": "defensa-y-justicia",
        "Estudiantes": "estudiantes",
        "Ferro Carril Oeste": "ferro-carril-oeste",
        "Gimnasia de La Plata": "gimnasia-de-la-plata",
        "Godoy Cruz": "godoy-cruz",
        "Huracán": "huracan",
        "Independiente": "independiente",
        "Independiente Rivadavia": "independiente-rivadavia",
        "Instituto de Córdoba": "instituto-de-cordoba",
        "Lanús": "lanus",
        "Newell's Old Boys": "newells-old-boys",
        "Nueva Chicago": "nueva-chicago",
        "Patronato de Paraná": "patronato-de-parana",
        "Platense": "platense",
        "Quilmes": "quilmes",
        "Racing Club": "racing-club",
        "River Plate": "river-plate",
        "Rosario Central": "rosario-central",
        "San Lorenzo": "san-lorenzo",
        "San Martín de San Juan": "san-martin-de-san-juan",
        "Sarmiento de Junín": "sarmiento-de-junin",
        "Talleres de Córdoba": "talleres-de-cordoba",
        "Tigre": "tigre",
        "Vélez Sarsfield": "velez-sarsfield"
    };

    const initialSearchParams = {
        club1: null,
        club2: null,
        pais: null,
        copaArgentina: null
    };

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useState(initialSearchParams);
    const [results, setResults] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);

    const [countries, setCountries] = useState([]);

    // Cargar los datos de los JSON al montar el componente
    useEffect(() => {
        import('../../data/countriesArg.json').then((data) => setCountries(Object.keys(data.default)));
    }, []);

    const handleInputChange = (e, field) => {
        const value = e.target.value || null;
        const prevValue = searchParams[field];

        if (value && prevValue === null && selectedCount < 2) {
            setSelectedCount(selectedCount + 1);
        } else if (value === null && prevValue !== null) {
            setSelectedCount(selectedCount - 1);
        }

        setSearchParams({ ...searchParams, [field]: value });
    };

    const handleReset = () => {
        setSearchParams(initialSearchParams);
        setResults([]);
        setSelectedCount(0);
    };

    const customLatinize = (text) => {
        return text.split(/(ñ)/gi).map(part => {
            return part.toLowerCase() === 'ñ' ? part : latinize(part);
        }).join('');
    };

    const fetchPlayers = async () => {
        try {
            // Filtrar los parámetros seleccionados que no sean nulos
            const selectedParams = Object.keys(searchParams).filter(key => searchParams[key]);
            const item1Value = selectedParams[0] ? customLatinize(searchParams[selectedParams[0]].toLowerCase()) : '';
            const item2Value = selectedParams[1] ? customLatinize(searchParams[selectedParams[1]].toLowerCase()) : '';

            const response = await fetch(`${'https://footy-294475855174.us-central1.run.app'}/players/searchByCriteria?item1=${item1Value}&item2=${item2Value}&league=arg`);
            const data = await response.json();
            setResults(data);
        } catch (error) {
            console.error('Error fetching players:', error);
            setResults([]);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchPlayers();
    };

    return (
        <div className="container">
            <Navbar />
            <button
                className="go-home-button"
                onClick={() => navigate('/')}
            >
                <img className="goHomeIcon" src={GoBackButton} alt="Back" />
            </button>
            <Title />
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-grid">
                        <div className="form-field">
                            <label>Club 1:</label>
                            <select value={searchParams.club1 || ""} onChange={e => handleInputChange(e, 'club1')} disabled={selectedCount === 2 && !searchParams.club1}>
                                <option value="">--Select a club--</option>
                                {Object.keys(clubMappings).map((club, index) => (
                                    <option key={index} value={clubMappings[club]}>{club}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-field">
                            <label>Club 2:</label>
                            <select value={searchParams.club2 || ""} onChange={e => handleInputChange(e, 'club2')} disabled={selectedCount === 2 && !searchParams.club2}>
                                <option value="">--Select a club--</option>
                                {Object.keys(clubMappings).map((club, index) => (
                                    <option key={index} value={clubMappings[club]}>{club}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-field">
                            <label>Country:</label>
                            <select value={searchParams.pais || ""} onChange={e => handleInputChange(e, 'pais')} disabled={selectedCount === 2 && !searchParams.pais}>
                                <option value="">--Select a country--</option>
                                {countries.map((country, index) => <option key={index} value={country}>{country}</option>)}
                            </select>
                        </div>
                        <div className="form-field">
                            <label>Copa Argentina:</label>
                            <select value={searchParams.copaArgentina || ""} onChange={e => handleInputChange(e, 'copaArgentina')} disabled={selectedCount === 2 && !searchParams.copaArgentina}>
                                <option value="">--Select an option--</option>
                                <option value="copa_argentina">Yes</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-field">
                        <button className="searchButton" type="submit" disabled={selectedCount < 2}>Search</button>
                        <button className="resetButton" type="button" onClick={handleReset}>Reset</button>
                    </div>
                </form>
                <div className="results-container">
                    {results.length > 0 ? (
                        results.map((result, index) => {
                            const year = result.fecha_nacimiento.split('.')[2]; // Extrae el año

                            return (
                                <div key={index} className="result-item">
                                    <><img src={result.img_pais} alt={result.img_pais} width="20" height="15" /> {result.nombre} ({year})</>
                                </div>
                            );
                        })
                    ) : (
                        <div className="result-item">
                            No results found.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PlayerFinderPageArg;