import React from 'react';
import './Square.css';
import classNames from 'classnames';

const Square = ({ value, onClick, turn, winner, playerName}) => {

    const handleClick = () => {
        if (turn !== null && value === '') {
            onClick();
        };
    }

    let squareClass = classNames({
        square: true,
        [`square--${value}`]: value !== '',
        winner: winner,
    });

    return (
        <div className={squareClass} onClick={() => handleClick()}>
            {value !== null && playerName && <span className="player-name">{playerName}</span>}
        </div>
    )
}

export default Square;
