import './PlayerSelected.css';
import React from 'react';

const PlayerSelected = ({ player }) => {
    return (
        <div className="player-selected-container">
            <div className="player-selected-country">
                <span>Player Selected: </span>
                <img src={player.img_pais} alt={player.pais} width="15" height="10" />
                <span> {player.nombre}</span>
            </div>
        </div>
    );
};

export default PlayerSelected;