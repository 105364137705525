import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './Logout.css';

function Logout() {
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        logout();

        setTimeout(() => {
            navigate('/');
        }, 2000);
    }, [logout, navigate]);

    return (
        <div className="logout-container">
            <div className="logout-box">
                <div className="spinner"></div>
                <div className="logout-content">
                    <h3>Logging out...</h3>
                </div>
            </div>
        </div>
    );
}

export default Logout;

