import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TimeLimitContext from '../../context/TimeLimitContext';
import { ToastContainer, toast } from 'react-toastify';
import { generateCustomArray } from '../../helpers/generateCustomArray';
import { generateArgArray } from '../../helpers/generateArgArray';
import { generateMexArray } from '../../helpers/generateMexArray';
import { generateUruArray } from '../../helpers/generateUruArray';
import { generateEngArray} from '../../helpers/generateEngArray';
import { winningPositions } from '../../helpers/winningPositions';
import { checkPlayer } from '../../helpers/checkPlayer';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar/Navbar';
import Title from '../../components/Title/Title';
import Board from '../../components/Board/Board';
import ScoreBoard from '../../components/ScoreBoard/ScoreBoard';
import NextButton from '../../components/NextButton/NextButton';
import PlayerSelected from '../../components/PlayerSelected/PlayerSelected';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import '../../App.css';

const LocalGamePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [turn, setTurn] = useState('X');
    const [squares, setSquares] = useState(Array(9).fill({ value: '', playerName: '' }));
    const [winningSquares, setWinningSquares] = useState([]);
    const [score, setScore] = useState({
        X: 0,
        O: 0,
    });
    const [league, setLeague] = useState('normal');
    const [items, setItems] = useState(generateCustomArray());
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const { timeLimit } = useContext(TimeLimitContext);
    const [timeRemaining, setTimeRemaining] = useState(timeLimit);
    const itemMaping = {
        0: [items[0], items[3]],
        1: [items[1], items[3]],
        2: [items[2], items[3]],
        3: [items[0], items[4]],
        4: [items[1], items[4]],
        5: [items[2], items[4]],
        6: [items[0], items[5]],
        7: [items[1], items[5]],
        8: [items[2], items[5]],
    };

    useEffect(() => {
        setTimeRemaining(timeLimit);

        const timer = setInterval(() => {
            setTimeRemaining((prevTimeRemaining) => {
                if (prevTimeRemaining === 0) {
                    handleSkipTurn();
                    setSelectedPlayer(null);
                    return timeLimit;
                }
                return prevTimeRemaining - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLimit, turn]);

    useEffect(() => {
        if (location.state && location.state.mode) {
            if (location.state.mode === 'normal') {
                setLeague('normal');
                setItems(generateCustomArray());
            } else if (location.state.mode === 'arg') {
                setLeague('arg');
                setItems(generateArgArray());
            } else if (location.state.mode === 'mex') {
                setLeague('mex');
                setItems(generateMexArray());
            } else if (location.state.mode === 'uru') {
                setLeague('uru');
                setItems(generateUruArray());
            } else if (location.state.mode === 'eng') {
                setLeague('eng');
                setItems(generateEngArray());
            } else {
                return;
            }
        }
    }, [location.state]);

    const handleSkipTurn = () => {
        setTurn((prevTurn) => (prevTurn === 'X' ? 'O' : 'X'));
    }

    const handleChangeItems = () => {
        if (league === 'normal') {
            setItems(generateCustomArray());
        } else if (league === 'arg') {
            setItems(generateArgArray());
        } else if (league === 'mex') {
            setItems(generateMexArray());
        } else if (league === 'uru') {
            setItems(generateUruArray());
        } else if (league === 'eng') {
            setItems(generateEngArray());
        }
    }

    const handlePlayerSelect = (player) => {
        setSelectedPlayer(player);
    }

    const handleClick = (square) => {
        if (!selectedPlayer) {
            toast.error('Select a player first', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }

        const [item1, item2] = itemMaping[square];
        const playedInItem1 = checkPlayer(selectedPlayer, item1);
        const playedInItem2 = checkPlayer(selectedPlayer, item2);

        if (playedInItem1 && playedInItem2) {
            let newSquares = [...squares];
            newSquares.splice(square, 1, { value: turn, playerName: selectedPlayer.nombre })
            checkForWinner(newSquares);
            setSquares(newSquares);
            setSelectedPlayer(null);
        } else {
            toast.warn('The player does not meet the requirements. 😔You lost the turn.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            handleSkipTurn();
            setSelectedPlayer(null);
        }
    }

    const checkForWinner = newSquares => {
        for (let i = 0; i < winningPositions.length; i++) {
            const [a, b, c] = winningPositions[i];
            if (newSquares[a] && newSquares[a].value !== '' && newSquares[a].value === newSquares[b].value && newSquares[a].value === newSquares[c].value) {
                endGame(newSquares[a].value, winningPositions[i])
                return
            }
        }

        if (!newSquares.some(square => square.value === '')) {
            endGame(null, Array.from(Array(10).keys()));
            return;
        }
        handleSkipTurn();
    }

    const endGame = (result, winningPositions) => {
        setTurn(null);
        if (result != null) {
            setScore({
                ...score,
                [result]: score[result] + 1,
            })
        }
        setWinningSquares(winningPositions);
        setTimeout(reset, 3000);
    }

    const reset = () => {
        const newTurn = turn === 'X' ? 'O' : 'X';
        setSquares(Array(9).fill({ value: '', playerName: '' }));
        setTurn(newTurn);
        setWinningSquares([]);
        setTimeRemaining(timeLimit);
        handleChangeItems();
    }

    const handleClickTitle = () => {
        navigate('/')
    }

    return (
        <div className="container">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Navbar />
            <Title onClick={handleClickTitle} />
            <ScoreBoard scoreO={score.O} scoreX={score.X} />
            {selectedPlayer && <PlayerSelected player={selectedPlayer} />}
            <NextButton onClick={reset} changeTurn={handleSkipTurn} turn={turn} />

            {timeRemaining !== null && <CountdownTimer timeRemaining={timeRemaining} />}

            <Board winningSquares={winningSquares} turn={turn} squares={squares} onClick={handleClick} clubSquares={items} handlePlayerSelect={handlePlayerSelect} player={turn} playerFootball={selectedPlayer} league={league} />
        </div>
    );
}

export default LocalGamePage;