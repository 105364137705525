import { useEffect } from 'react';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';

const useFirebaseAuth = (setLoading, setUser) => {
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // El usuario ya está autenticado, por lo que lo guardamos en el estado.
        setUser(firebaseUser);
        setLoading(false);
      } else {
        // El usuario no está autenticado, intentamos iniciar una sesión anónima.
        signInAnonymously(auth)
          .then(({ user }) => {
            setUser(user);
            setLoading(false);
          })
          .catch((signInError) => {
            console.error('Error')
            setLoading(false);
          });
      }
    });

    return () => unsubscribe();
  }, [setLoading, setUser]);
};

export default useFirebaseAuth;

