import './ScoreBoard.css';

const ScoreBoard = ({scoreX, scoreO}) => (
    <div className='score-board'>
        <div className='team-score team-x'>
            <span className='score'>{scoreX}</span>
        </div>
        <div className='separator'>:</div>
        <div className='team-score team-o'>
            <span className='score'>{scoreO}</span>
        </div>
    </div>
);

export default ScoreBoard;

