export function checkPlayer(player, item) {
  // Check clubs, countries and coach
  if (checkAffiliations(player, item)) {
    return true;
  }

  // Check boolean conditions
  if (player[item] === true) {
    return true;
  }

  // Check age
  const age = calculateAge(player.fecha_nacimiento);
  if ((item === "25" && age < 25) || (item === "35" && age >= 35)) {
    return true;
  }

  // Check transfer value
  const match = item.match(/^(\d+)transferValue$/);
  if (match) {
    const minValue = parseInt(match[1], 10);
    if (player.maxTransferValue >= minValue) {
      return true;
    }
  }

  return false;
}

function checkAffiliations(player, item) {
  return player.clubes.includes(item) ||
    player.pais.includes(item) ||
    (player.dirigidoPor && player.dirigidoPor.includes(item)) ||
    (player.posicion && player.posicion.includes(item)) ||
    (player.teammates && player.teammates.includes(item));
}

function calculateAge(birthDate) {
  const currentDate = new Date();
  const birthDateParts = birthDate.split(".");
  const birthDateObj = new Date(`${birthDateParts[2]}-${birthDateParts[1]}-${birthDateParts[0]}`);
  let age = currentDate.getFullYear() - birthDateObj.getFullYear();
  const m = currentDate.getMonth() - birthDateObj.getMonth();
  if (m < 0 || (m === 0 && currentDate.getDate() < birthDateObj.getDate())) {
    age--;
  }
  return age;
}
