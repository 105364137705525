import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
/* import AuthRoute from './AuthRoute'; */
import NoAuthRoute from './NoAuthRoute';
import AdminRoute from "./AdminRoute";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import Logout from "../pages/Logout/Logout";
import HomePages from "../pages/HomePage/HomePage";
import LocalGamePages from "../pages/LocalGamePage/LocalGamePage";
import SoloBotPage from "../pages/SoloBotPage/SoloBotPage";
import SoloBotPageArg from "../pages/SoloBotPage/SoloBotPageArg";
import RoomManagerPage from "../pages/RoomManagerPage/RoomManagerPage";
import OnlineGamePages from "../pages/OnlineGamePage/OnlineGamePage";
import PlayerFinderPage from "../pages/PlayerFinderPage/PlayerFinderPage";
import PlayerFinderPageArg from "../pages/PlayerFinderPage/PlayerFinderPageArg";
import RankingPage from "../pages/RankingPage/RankingPage";
import PoliticaPrivacidad from "../pages/PoliticaPrivacidad/PoliticaPrivacidad";
import AdminPage from "../pages/AdminPage/AdminPage";
import TimeLimitContext from "../context/TimeLimitContext";

function AppRoutes() {
    const [timeLimit, setTimeLimit] = useState(60);

    return (
        <TimeLimitContext.Provider value={{ timeLimit, setTimeLimit }} >
            <Router>
                <Routes>
                    <Route path="/" element={<HomePages />} />
                    <Route path="/login" element={<NoAuthRoute><Login /></NoAuthRoute>} />
                    <Route path="/register" element={<NoAuthRoute><Register /></NoAuthRoute>} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/local" element={<LocalGamePages />} />
                    <Route path="/room" element={<RoomManagerPage />} />
                    <Route path="/room/:urlRoomCode" element={<OnlineGamePages />} />
                    <Route path="/player-finder" element={<PlayerFinderPage />} />
                    <Route path="/player-finder-arg" element={<PlayerFinderPageArg />} />
                    <Route path="/solo-bot" element={<SoloBotPage />} />
                    <Route path="/solo-bot-arg" element={<SoloBotPageArg />} />
                    <Route path="/ranking" element={<RankingPage />} />
                    <Route path="/privacy-policy" element={<PoliticaPrivacidad />} />
                    <Route path="/admin" element={<AdminRoute><AdminPage /></AdminRoute>} />
                    <Route path="*" element={<HomePages />} />
                </Routes>
                <ToastContainer />
            </Router>
        </TimeLimitContext.Provider>
    );
}

export default AppRoutes;



