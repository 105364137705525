import clubs from '../data/clubsArg.json'
import countries from '../data/countriesArg.json'
import years from '../data/years.json'
import achievements from '../data/achievementsArg.json'
import positions from '../data/positions.json'

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function isValidResult(result) {
    let highRatedClubsCount = 0;
    let countriesCount = 0;
    let yearsCount = 0;
    let positionsCount = 0;
    let campeonMundoIndex = -1;

    for (let i = 0; i < result.length; i++) {
        if (clubs[result[i]] >= 4) highRatedClubsCount++;
        if (countries.hasOwnProperty(result[i])) countriesCount++;
        if (years.hasOwnProperty(result[i])) yearsCount++;
        if (result[i] === "campeon_mundo") campeonMundoIndex = i;
    }

    if (highRatedClubsCount < 3) return false;
    if (yearsCount > 1) return false;

    const notAllowedCountriesForCampeonMundo = ["Chile", "Uruguay", "Colombia", "Paraguay"];

    for (let i = 0; i < result.length; i++) {
        if (positions.hasOwnProperty(result[i])) positionsCount++;
    }

    if (positionsCount > 1) return false;

    if (campeonMundoIndex >= 0) {
        if (campeonMundoIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedCountriesForCampeonMundo.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedCountriesForCampeonMundo.includes(result[i])) return false;
            }
        }
    }

    if (countriesCount > 0) {
        let firstHalfCountryCount = 0;
        let secondHalfCountryCount = 0;
        for (let i = 0; i < 3; i++) {
            if (countries.hasOwnProperty(result[i])) firstHalfCountryCount++;
            if (result[i] === "african_player") firstHalfCountryCount++;
        }
        for (let i = 3; i < result.length; i++) {
            if (countries.hasOwnProperty(result[i])) secondHalfCountryCount++;
            if (result[i] === "african_player") secondHalfCountryCount++;
        }
        if (firstHalfCountryCount > 0 && secondHalfCountryCount > 0) return false;
    }
    return true;
}


function getRandomItemByCategory() {
    const clubItems = Object.keys(clubs);
    const countryItems = Object.keys(countries);
    const yearItems = Object.keys(years);
    const achievementItems = Object.keys(achievements);
    const positionItems = Object.keys(positions);

    const clubWeight = 0.6;
    const countryWeight = 1.0;
    const yearWeight = 0.6;
    const achievementWeight = 1.0;
    const positionWeight = 0.5;

    const totalWeight = clubItems.length * clubWeight +
        countryItems.length * countryWeight +
        yearItems.length * yearWeight +
  /*       achievementItems.length * achievementWeight + */
        positionItems.length * positionWeight;

    const randomValue = Math.random() * totalWeight;
    let accumulatedWeight = 0;

    for (let item of clubItems) {
        accumulatedWeight += clubWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of countryItems) {
        accumulatedWeight += countryWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of yearItems) {
        accumulatedWeight += yearWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of achievementItems) {
        accumulatedWeight += achievementWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of positionItems) {
        accumulatedWeight += positionWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    throw new Error("No se pudo seleccionar un elemento aleatorio.");
}

export function generateArgArray() {
    let result;
    do {
        const selectedItems = new Set();

        while (selectedItems.size < 6) {
            const randomItem = getRandomItemByCategory();
            selectedItems.add(randomItem);
        }

        result = Array.from(selectedItems);
        shuffleArray(result);
    } while (!isValidResult(result));

    return result;
}