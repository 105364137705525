import {
    app
} from '../firebase';
import {
    getDatabase,
    ref,
    set,
    get,
    update,
    serverTimestamp
} from "firebase/database";
import {
    generateRoomCode
} from './generateRoomCode';


export const createRoom = async (userUid, items, mode, timeLimit) => {
    const db = getDatabase(app);
    const roomCode = generateRoomCode();
    const roomRef = ref(db, `rooms/${roomCode}`);

    try {
        await set(roomRef, {
            player1: userUid,
            squares: Array(9).fill({value: '', playerName: ''}),
            items: items,
            turn: 'X',
            isGameStarted: false,
            timeLimit: timeLimit,
            roomCode: roomCode,
            mode: mode,
            lastUpdate: serverTimestamp()
        });
        return roomCode;
    } catch (error) {
        console.error("Error al crear la sala:", error);
    }
};

export const joinRoom = async (userUid, roomCode) => {
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);

    // Obtener los datos de la sala
    const snapshot = await get(roomRef);
    if (snapshot.exists()) {
        const roomData = snapshot.val();
        if (userUid === roomData.player1) {
            return roomCode;
        } else if (userUid === roomData.player2) {
            return roomCode;
        } else if (!roomData.player2) {
            await update(roomRef, {
                player2: userUid,
                isGameStarted: true,
                lastUpdate: serverTimestamp(),
            });
            return roomCode;
        } else {
            alert("Error al unirse a la sala");
            return;
        }
    }
    else {
        console.error("Error al unirse a la sala: la sala no existe");
    }
};

export async function updateItems(roomCode, newItems) {
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);
    await update(roomRef, {
        items: newItems
    });
}

export async function updateSelectedPlayer(roomCode, player) {
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);
    await update(roomRef, {
        selectedPlayer: player
    });
}

export function skipTurn(roomCode, newTurn, timeLimit) {
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);
    update(roomRef, {
        turn: newTurn,
        timeRemaining: timeLimit
    });
}

export async function updateGameStateAndPlayer(roomCode, newTurn, squares, selectedPlayer) {
    const updatedSquares = squares.map(square => (square === null ? '' : square));
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);
    await update(roomRef, {
        turn: newTurn,
        squares: updatedSquares,
        selectedPlayer: selectedPlayer,
        lastUpdate: serverTimestamp()
    });
}

export async function updateGameStateEnd(roomCode, squares, selectedPlayer) {
    const updatedSquares = squares.map(square => (square === null ? '' : square));
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);
    await update(roomRef, {
        squares: updatedSquares,
        selectedPlayer: selectedPlayer,
        lastUpdate: serverTimestamp()
    });
}

export async function checkWinningSquares(roomCode, winningSquares) {
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);
    await update(roomRef, {
        winningSquares: winningSquares
    });
}

export function resetFirebase(roomCode, turn, squares) {
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);
    update(roomRef, {
        turn: turn,
        squares: squares,
        winningSquares: [''],
        lastUpdate: serverTimestamp()
    });
}

export function updateScore(roomCode, score) {
    const db = getDatabase(app);
    const roomRef = ref(db, `rooms/${roomCode}`);
    update(roomRef, {
        score: score
    });
}